import { API_ORIGIN, HTTP_METHODS } from "../Utils/constant";
import HttpService from "./http.service";

class EnterpriseService {

  static getEnterpriseDetails = async (urlCompanyName, accessCode) => {
    try {
      var endpoint = API_ORIGIN + 'enterprise-details';

      if (urlCompanyName || accessCode) {
        endpoint = endpoint + '?'
      }
      if (urlCompanyName) {
        endpoint = endpoint + 'urlCompanyName=' + urlCompanyName;
      }
      if (accessCode) {
        endpoint = endpoint + '&accessCode=' + accessCode;
      }
      const response = await HttpService.sendRequest(
        endpoint,
        HTTP_METHODS.GET,
      );

      const status = await response.json();

      return status;
    } catch (error) {
      throw error;
    }
  }
}

export default EnterpriseService;